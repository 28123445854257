module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-186127969-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":"Table of Contents","tight":false,"fromHeading":1,"toHeading":2}},{"resolve":"gatsby-remark-external-links"},{"resolve":"gatsby-remark-images","options":{"maxWidth":600,"showCaptions":false,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files"},"gatsby-remark-autolink-headers"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"moin!: 蒐集回饋，讓您的用戶開心","short_name":"moin!","start_url":"/","background_color":"#FFFFFF","theme_color":"#E48C15","display":"minimal-ui","icon":"static/img/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5334f194725576fc4ea7d5f816ab2ed"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
